<template>
  <v-app id="main-app">
    <v-container
      class="fill-height"
      fluid
    >
      <v-card
        class="mx-auto px-5 pb-7"
        max-width="450px"
        min-width="300px"
        min-height="100px"
        :loading="loading"
        elevation="7"
        rounded
        outlined
      >
        <alert
          v-if="alert"
          class="mb-0"
          :type="alertType"
          dense
          text
        >
          {{ alertMessage }}
        </alert>
        <v-card-title class="justify-center pt-12 mb-12">
          <h3>{{ tittle }}</h3>
        </v-card-title>
        <v-card-text class="text-center">
          <v-form
            v-if="showForm"
            @submit.prevent="login"
          >
            <v-text-field
              v-model.trim="email"
              filled
              rounded
              dense
              autocomplete="username"
              label="Email"
              name="email"
              required
              type="email"
              :error-messages="errorMessage"
              :rules="[rules.emailRequired]"
              @keyup.enter="clickButton"
            />

            <v-text-field
              v-model="password"
              filled
              rounded
              dense
              label="Password"
              name="password"
              required
              spellcheck="false"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :autocomplete="showPassword ? 'off' : 'current-password'"
              :rules="[rules.passwordRequired]"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              @keyup.enter="clickButton"
            />
          </v-form>
          <v-form
            v-else
            @submit.prevent="sendEmail"
          >
            <v-text-field
              v-model.trim="activationEmail"
              filled
              rounded
              dense
              label="Email"
              name="email"
              required
              type="email"
              :error-messages="errorMessage"
              :rules="[rules.activationEmailRequired]"
              @keyup.enter="clickButton"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex align-center">
          <v-btn
            text
            color="primary"
            @click="forgotPassword"
          >
            {{ btnLeft }}
          </v-btn>
          <v-btn
            color="primary"
            @click="clickButton"
          >
            {{ btnRight }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-footer
        color="transparent"
        absolute
        padless
      >
        <v-col
          class="text-center white--text"
          cols="12"
        >
          &copy;{{ new Date().getFullYear() }}
        </v-col>
      </v-footer>
    </v-container>
  </v-app>
</template>

<script>
  import { AuthenticationError } from '@/lib/Errors'
  import { mapState } from 'vuex'
  import $http from '@/plugins/axios'
  import alert from '@/components/base/MaterialAlert.vue'

  export default {
    components: {
      alert,
    },
    data () {
      return {
        alert: false,
        alertType: '',
        alertMessage: '',
        loading: false,
        showForm: true,
        tittle: 'Sign In',
        btnLeft: 'Forgot Password?',
        btnRight: 'Sign In',
        activationEmail: '',
        email: '',
        password: '',
        errorMessage: '',
        showPassword: false,
        rules: {
          emailRequired: value => !!value || 'Enter your email address',
          passwordRequired: value => !!value || 'Enter your password',
          activationEmailRequired: value => !!value || 'Enter your email address',
        },
      }
    },
    computed: {
      ...mapState({

      }),
    },
    watch: {
      email () {
        this.errorMessage = ''
      },
    },
    methods: {
      async login () {
        this.errorMessage = ''
        this.loading = true
        try {
          await this.$store.dispatch('authentication/doLogin', { email: this.email, password: this.password })

          // NOTE: By definition, if we get here, the login is good,
          //       because a bad login will throw an exception.
          const role = this.$store.getters['authentication/getRole']
          if (role === 'CUSTOMER_ADMIN') {
            this.$router.replace(this.$route.query.redirect || '/pages/stores')
          } else if (role === 'SYSTEM_MASTER') {
            this.$router.replace(this.$route.query.redirect || '/pages/customers')
          } else if (role === 'REGIONAL_ADMIN') {
            this.$router.replace(this.$route.query.redirect || '/pages/orders')
          } else if (role === 'BRANCH_ADMIN') {
            this.$router.replace(this.$route.query.redirect || '/pages/orders')
          } else this.$router.replace(this.$route.query.redirect || '/')
          this.loading = false
        } catch (error) {
          if (error instanceof AuthenticationError) {
            this.errorMessage = error.message
          } else {
            this.errorMessage = 'Error communicating with server. Please try again.'
          }
          this.loading = false
        }
      },
      clickButton () {
        if (this.showForm) {
          return this.login()
        } else {
          return this.sendEmail()
        }
      },
      async sendEmail () {
        const body = {
          email: this.activationEmail,
        }
        await $http.post('/user/sendvalidationlinkbyemail', body)
          .then((response) => {
            if (response.data.resFlag) {
              this.alertType = 'info'
              this.alertMessage = 'An e-mail was sent to your e-mail address.Please check your inbox and confirm.'
              this.alert = true
              window.setInterval(() => {
                this.alert = false
              }, 5000)
            } else {
            }
          })
          .catch(() => {
            this.alertType = 'error'
            this.alertMessage = 'Something went wrong.Please try again.'
            this.alert = true
            window.setInterval(() => {
              this.alert = false
            }, 5000)
          })
      },
      forgotPassword () {
        this.showForm = !this.showForm
        if (this.showForm) {
          this.btnLeft = 'Forgot Password?'
          this.btnRight = 'Sign In'
          this.tittle = 'Sign In'
          this.alert = false
        } else {
          this.btnLeft = 'Back'
          this.btnRight = 'Send Email'
          this.tittle = 'Find your profile'
        }
      },
    },
  }
</script>
<style scoped>
    #main-app {
      /* background: rgb(34,193,195);
      background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,177,45,1) 100%); */
      background: url('../../../assets/main-page.jpeg') no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
    }
</style>
